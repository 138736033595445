<template>
  <div class="row">
    <div class="col-12">
      <div
        class="w-100 row mx-0 px-0 d-flex justify-content-center mb-2 mt-2 my-following-search"
      >
        <div class="row justify-content-center">
          <div class="col-12 row">
            <div class="col-12 my-2 my-md-0 my-following-date-filter px-8">
              <date-filter
                :helper-text="$t('general.start_date')"
                title="general.start_date"
                :model.sync="filters.start_date"
                name="start_time"
              >
              </date-filter>
            </div>
            <div class="col-12 my-2 my-md-0 my-following-date-filter px-8">
              <date-filter
                :helper-text="$t('general.end_date')"
                title="general.end_date"
                :model.sync="filters.end_date"
                name="end_date"
                class="my-following-date-filter"
              >
              </date-filter>
            </div>
            <div class="col-12 my-2 my-md-0 py-1 my-following-multiselect">
              <custom-multi-select
                :isInline="false"
                :model.sync="filters.supplier_company_ids"
                :options="supplierCompany"
                :required="true"
                :title="$t('following.supplier_company_filter')"
                name="supplier_company_filter"
                :multiple="true"
                @save="getCompanyIndex($event)"
              ></custom-multi-select>
            </div>
          </div>
          <div class="col-12 mt-3 row" style="margin: 0 !important">
            <div class="col-12 search-button-area my-2">
              <button
                class="w-25 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
                @click="filterResult(null)"
              >
                {{ $t("general.filter") }}
              </button>
              <button
                type="button"
                @click="resetFilters()"
                class="w-25 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.clear") }}
              </button>
              <button
                @click="exportPackingListTable()"
                type="button"
                class="w-25 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.export_to_excel") }}
              </button>
              <!-- TODO: pdf listeye aktar -->
              <button
                type="button"
                v-if="false"
                @click="exportPackingListPdf()"
                class="w-25 btn btn-sm btn-outline-primary rounded-full font-weight-bold py-1 mt-1"
              >
                {{ $t("general.export_to_list") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="w-100 row mx-0 px-0 d-flex justify-content-center">
        <table class="table table-responsive" ref="table">
          <thead class="main-table" ref="thead">
            <tr>
              <th class="font-weight-bold text-xl">
                <div class="w-100px text-center">
                  {{ $t("general.date") }}
                </div>
              </th>
              <th
                scope="col"
                class="py-1 font-weight-bold text-xl justify-content-center"
                v-for="tableItem in tableItems"
              >
                <div class="text-center">
                  {{ tableItem[0]["supplier_company_name"] }}
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="date in filterResultDates">
              <td
                class="text-center navy-blue-border-bottom-1px navy-blue-border-right-1px m-0 p-0 w-100px align-items-center"
              >
                <span class="date">{{ date }}</span>
              </td>
              <td
                v-for="tableItem in tableItems"
                class="justify-content-center navy-blue-border-bottom-1px navy-blue-border-right-2px m-0 p-0"
              >
                <table class="d-flex justify-content-start">
                  <tbody class="inner-table">
                    <tr>
                      <template
                        v-for="dateInTableItems in tableItem"
                        v-if="dateInTableItems.date == date"
                      >
                        <td class="text-center" style="border-top: transparent">
                          <div class="order-number">
                            <span class="m-o p-0 m-0 p-1 text-lg">{{
                              dateInTableItems.order_number
                            }}</span>
                          </div>
                        </td>
                        <td class="text-center" style="border-top: transparent">
                          <div class="order-info-area text-center">
                            <div class="target-area">
                              <span class="m-o p-0 text-sm">
                                {{ $t("following.target") }}:
                              </span>
                              <span class="m-o p-0 text-sm">{{
                                dateInTableItems.target
                              }}</span>
                            </div>
                          </div>
                        </td>
                        <td
                          class="text-center font-weight-bold navy-blue-border-right-2px"
                          style="border-top: transparent"
                        >
                          <div class="order-info-area text-center">
                            <div class="actual-area">
                              <span class="m-o p-0 text-sm"
                                >{{ $t("following.standing") }}:
                              </span>
                              <span class="m-o p-0 text-sm">{{
                                dateInTableItems.ironActual
                              }}</span>
                            </div>
                          </div>
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  GET_ITEMS,
  HANDLE_INFINITE_SCROLL,
  LOADING,
  SET_ITEMS,
  SET_LOADING,
} from "@/core/services/store/REST.module";
import {
  EXPORT,
  EXPORT_PDF,
} from "@/core/services/store/following-production/following_production.module";
import moment from "moment";
import {
  LARAVEL_DATE_FORMAT,
  LARAVEL_DATE_TIME_FORMAT,
  MOMENT_SYSTEM_DATE_FORMAT,
} from "@/core/config/constant";
import DataTable from "@/assets/components/dataTable/DataTable";

import "vue-simple-calendar/static/css/default.css";
import "vue-simple-calendar/static/css/holidays-us.css";
import "vue-simple-calendar/static/css/gcal.css";
// TODO: remove import and delete file
import { CalendarMathMixin, CalendarView, CalendarViewHeader } from "vue-simple-calendar"; // published version
import Vue from "vue";
import VueExcelEditor from "vue-excel-editor";
import InputFilter from "@/assets/components/filters/InputFilter";
import SelectFilter from "@/assets/components/filters/SelectFilter";
import DateFilter from "@/assets/components/filters/DateFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import {FOLLOWING_PRODUCTION_TABLE_END_DATE, FOLLOWING_PRODUCTION_TABLE_START_DATE} from "@/core/storage/storage-names"
export default {
  name: "FollowingProductionTableView",
  components: {
    TextInput,
    InputFilter,
    DashboardBox,
    DataTable,
    CalendarView,
    CalendarViewHeader,
    DateFilter,
    CustomMultiSelect,
    SelectFilter,
  },
  mixins: [CalendarMathMixin],
  beforeRouteLeave(to, from, next) {
    this.config.content.width = "fixed";
    next();
  },
  computed: {
    ...mapGetters({
      loading: LOADING,
      layoutConfig: ["layoutConfig"],
    }),
    config() {
      return this.layoutConfig();
    },
  },
  data() {
    return {
      supplierCompany: [],
      tableItems: [],
      isShowingPopUp: false,
      popShowItemDetailsItem: [],
      filterResultDates: [],
      filters: {
        search: null,
        start_date: moment().subtract(30, "d"),
        end_date: moment().add(1, "d"),
        supplier_company_ids: [],
      },
    };
  },
  methods: {
    getCompanyIndex(companyId) {
      return this.tableItems.findIndex((item) => item.id === companyId);
    },
    resetFilters() {
      this.filters = {
        search: null,
        start_date: moment(this.showDate).subtract(1, "days"),
        end_date: moment(this.showDate).add(1, "month"),
        supplier_company_ids: [],
      };
    },
    filterResult() {
      localStorage.setItem(FOLLOWING_PRODUCTION_TABLE_END_DATE, moment(this.filters.start_date));
      localStorage.setItem(FOLLOWING_PRODUCTION_TABLE_START_DATE, moment(this.filters.end_date));

      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "supplier_company_ids",
        this.filters.supplier_company_ids.join(",")
      );
      this.$store
        .dispatch(GET_ITEMS, {
          url: "api/following-production/get_order_expense_target_list",
          acceptPromise: true,
          filters: filters,
          showLoading: true,
        })
        .then((result) => {
          if (result.status) {
            let items = result.data;
            this.tableItems = items.data;
            this.filterResultDates = items.dates;
          }
        });
    },
    exportPackingListTable() {
      let filters = _.cloneDeep(this.filters);
      this.$set(
        filters,
        "start_date",
        moment(this.filters.start_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "end_date",
        moment(this.filters.end_date).format(LARAVEL_DATE_FORMAT)
      );
      this.$set(
        filters,
        "supplier_company_ids",
        this.filters.supplier_company_ids.join(",")
      );
      this.$store
        .dispatch(EXPORT, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "following_production_list" + day + ".xlsx";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    exportPackingListPdf() {
      let filters = {
        start_date: moment(this.selected_start_date).format(LARAVEL_DATE_FORMAT),
        end_date: moment(this.selected_end_date).format(LARAVEL_DATE_FORMAT),
      };
      this.$store
        .dispatch(EXPORT_PDF, {
          filters: filters,
        })
        .then((result) => {
          if (result.status) {
            let fileURL = window.URL.createObjectURL(new Blob([result.data]));
            let fileLink = document.createElement("a");
            fileLink.href = fileURL;
            let day = moment().format("YYYYMMDDHHMMSS");
            let fileName = "following_production_list" + day + ".pdf";
            fileLink.setAttribute("download", fileName);
            document.body.appendChild(fileLink);
            fileLink.click();
          }
        });
    },
    getHumanDate: function (date) {
      return moment(date, "YYYY-MM-DD").format("DD.MM.YYYY");
    },
  },
  created() {
    this.$store.commit(SET_LOADING, false);
    this.$store.commit(SET_ITEMS, {});
  },
  created() {
    this.$store.commit(SET_ITEMS, {});
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("menu.following_production"),
        route: "following_production.index",
      },
      { title: this.$t("general.dashboard") },
    ]);
    if (!this.isUserGranted("FollowingProduct", ["viewAny"])) {
      return false;
    }

    if(!this.filters){
      this.resetFilters();
    }

    this.filters.end_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TABLE_START_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TABLE_START_DATE))
      : moment().add(1, 'd').format(LARAVEL_DATE_FORMAT);

    this.filters.start_date = localStorage.hasOwnProperty(FOLLOWING_PRODUCTION_TABLE_END_DATE)
      ? moment(localStorage.getItem(FOLLOWING_PRODUCTION_TABLE_END_DATE))
      : moment().subtract(30, 'd').format(LARAVEL_DATE_FORMAT);

      
    this.config.content.width = "fluid";

    this.filterResult();
    let self = this;
    this.$store
      .dispatch(GET_ITEMS, {
        url: "api/planting-companies",
        acceptPromise: true,
        filters: { all: true },
        showLoading: true,
      })
      .then((result) => {
        if (result.status) {
          self.supplierCompany = self.convertArrayToObjectByKey(
            result.data,
            "id",
            "name"
          );
        }
      });
  },
};
</script>

<style lang="scss" scoped>
$cut_color: #e097ff;
$knit_color: #ffac70;
$iron_color: #ff95d5;
$primary_color: #462985;

$yellow_color: #f3f0d6;
$green_color: #d6f3eb;
$pink_color: #ffd5d7;

.inner-table {
  td {
    padding: 0px;
    span {
      display: grid;
      padding: 2px;
    }
  }
  td:last-child {
    border-right: none !important;
  }
}
.order-info-area {
  display: flex;
  flex-direction: column;

  span {
    margin-top: 0px;
  }
}

.main-table {
  table-layout: fixed;
  word-wrap: break-word;
  thead {
    th {
      div {
        min-width: 200px !important;
        width: 100% !important;
      }
    }
  }
  tr {
    color: white;
    background-color: $primary_color;
    border-color: white;

    th {
      border-right: 2px solid white;
      font-weight: 900 !important;
    }
  }
}
.order-number {
  display: flex !important;
  flex-direction: column !important;
  margin-left: 5px;
  background-color: $pink_color;
  margin-top: 2px;
  margin-bottom: 2px;
  font-weight: 700 !important;
}
.target-area {
  text-align: center;
  background-color: $green_color;
}
.actual-area {
  text-align: center;
  margin-right: 5px;
  background-color: $yellow_color;
}
.date {
  font-weight: 600;
}
.fixed {
  position: fixed;
  top: 25px;
  z-index: 1;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}
</style>

<style lang="scss">
//mobile styles
@import "../../../assets/sass/mobile/following-production/calendar.scss";
</style>
